import * as React from 'react'
import Poem from '../../src/shared/PoemLoop'
import Data from '../pages/data/minnen-1.json'
import styled from 'styled-components'
import Container from '../shared/Full'
import {Link} from 'gatsby'
import { Helmet } from 'react-helmet'
import ogImg from '../images/kusinen.png'

const Kusinen = () => {

    const PageContainer = styled.div`
        padding-left: 10px;
        padding-right: 10px;
        max-width: 1200px;
        width: 100%;
        height: auto;
        margin: 0 auto;
        postition: relative;
        display: flex; 
        flex-direction: row;
        flex-wrap: wrap; 
        justify-content: center;
        align-items: stretch;
        align-content: center;
        @media only screen
            and (min-width: 980px)
            and (max-width: 1400px) {
                max-width: 980px;
            }
        @media only screen
        and (min-width: 720px)
        and (max-width: 980px) {
            max-width: 700px;
        }
        @media only screen
        and (min-width: 580px)
        and (max-width: 720px) {
            width: 100%;
        }
    `;

    const Row = styled.div`
        width: 100%;
        height: auto;
        display: inline-flex;
        @media only screen
            and (min-width: 320px)
            and (max-width: 980px) {
                flex-direction: column;
            }
    `;

    const Column = styled.div`
        width: 100%;
        height: auto;
        text-align: center;
        padding-top: 50px;
    `;

    const PageHeader = styled.div`
        width: 100%;
        height: 75vh;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: stretch;
        align-content: center;
        text-align: center;
        border-bottom: 2px solid #fff;

        & a {
            font-size: 24px;
            color: #fff;
            text-decoration: none;
        }
    `;

    return(
        <>
        <Container>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Rolf Börjlind - Minnen - 1. kusinen</title>
            <meta name="description" content="Den första delen av en samling minnen. Den här delen heter Kusinen." />
            <meta property="og:image" content={ogImg} />
        </Helmet>
            <PageContainer>
                <PageHeader>
                    <h1>en sorgsen malström <br/>av minnen</h1>
                    {/* <Link to="/kusinen/">kusinen</Link>
                    <Link to="/den-osynlige/">den osynlige</Link>
                    <Link to="/en-vacker-yngling">en vacker yngling</Link> */}
                </PageHeader>
                <Row>
                    <Column>
                         <h2>1.<br/> kusinen</h2>
                         <br />
                         <h3><em>som en akt av längtan</em></h3>
                        <Poem data={Data} />
                    </Column>
                </Row>
            </PageContainer>
        </Container>
        </>
    )

}

export default Kusinen